import React from "react";
// import About from "../components/About/About";
import Home from "../components/Home/Home";

import Art from "../components/Art/Art";
import Engineering from "../components/Engineering/Engineering";

const routes = {
  "/": () => <Home />,
  // "/about": () => <About />,
  "/art": () => <Art />,
  "/engineering": () => <Engineering />,

  // "/designers": () => <Designers />,
  // "/alterations": () => <Alterations />,
  // "/vendors": () => <Vendors />,
  // "/events": () => <Events />,
  // "/contact": () => <Contact />,
  // "/our-store": () => <OurStore />,
};
export default routes;
