import React from "react";
import "./Home.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import sophiaLogo from "../../resources/sophia-sig.png";

function Home(props) {
  return (
    <React.Fragment>
      <Nav />

      <div className="container home-container">
        <h1 className="mt-4 mb-3 text-center">Sophia Leon</h1>
        {/* <p className="text-center">hi</p> */}
        {/* <a href="">Art</a>
        <br></br>
        <a href="">Engineering</a>
        <br></br> */}
        <img src={sophiaLogo} className="sophia-logo" />
        <div className=""></div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Home;
