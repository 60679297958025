import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import artJson from "./artJson";
import Gallery from "../Gallery/Gallery-old";

function Art(props) {
  const additionalModalHtml = (
    <React.Fragment>
      <p>Sophia Leon Art</p>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Nav />

      <div className="container dresses-container">
        <h1 className="mt-4 mb-3 text-center">Art</h1>
        {/* <h2 className="text-center mb-5">Browse Our Selection</h2> */}
        <Gallery
          additionalModalHtml={additionalModalHtml}
          itemJson={artJson.itemJson}
          title="Art"
        />
      </div>

      {/* <a
        href="https://app.bridallive.com/bl-server/appointmentScheduler/b78b5509/wrapper.shtml?lang=en"
        className="book-btn"
      >
        <i className="fa fa-calendar fab" aria-hidden="true"></i>
      </a> */}

      <Footer />
    </React.Fragment>
  );
}
export default Art;
