import React from "react";
import "./Gallery.css";
import { useSwipeable } from "react-swipeable";

function Modal(props) {
  const handlers = useSwipeable({
    onSwipedRight: () => props.handleModalClose(),
  });

  return (
    <React.Fragment>
      <div className="modal" {...handlers}>
        <span className="close" onClick={() => props.handleModalClose()}>
          &times;
        </span>
        <img className="modal-content" src={props.item.img} alt="modal" />
        <div className="modal-caption" style={{ marginBottom: 200 }}>
          {props.item.caption1 && <p>{props.item.caption1}</p>}
          {props.item.caption2 && <p>{props.item.caption2}</p>}
          {props.additionalHtml && props.additionalHtml}
        </div>
      </div>
    </React.Fragment>
  );
}
export default Modal;
