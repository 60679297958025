import React, { useState } from "react";
import "./Nav.css";
import NavItem from "./NavItem";
import sophiaLogo from "../../resources/sophia-sig.png";

const Nav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const show = menuOpen ? "show" : "";

  return (
    <React.Fragment>
      <div className="nav-container">
        <nav className={"navbar navbar-expand-lg navbar-light custom-navbar"}>
          <a className="nav-link-logo" href="/">
            <img src={sophiaLogo} className="nav-logo" />
            {/* <h1 className="nav-company-name">Bowties Bridal</h1> */}
          </a>

          <button
            className="navbar-toggler no-bs-border"
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={"collapse  navbar-collapse " + show}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              <NavItem path="/" name="Home" />
              <NavItem path="/art" name="Art" />
              <NavItem path="/engineering" name="Engineering" />
            </ul>
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Nav;
