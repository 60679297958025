import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
// import artJson from "./artJson";
import Gallery from "../Gallery/Gallery-old";
import engineeringJson from "./engineeringJson";

function Engineering(props) {
  const additionalModalHtml = (
    <React.Fragment>
      <p>Sophia Leon Engineering</p>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Nav />

      <div className="container dresses-container">
        <h1 className="mt-4 mb-3 text-center">Engineering</h1>
        {/* <h2 className="text-center mb-5">Browse Our Selection</h2> */}
        <Gallery
          additionalModalHtml={additionalModalHtml}
          itemJson={engineeringJson.itemJson}
          title="Engineering"
        />
      </div>

      <Footer />
    </React.Fragment>
  );
}
export default Engineering;
