import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "./Gallery.css";
import LottieAnimation from "../LottieAnimation/LottieAnimation";
import loadingLottie from "../../resources/lotties/bizzy-body-loading-lottie.json";
import Modal from "./Modal";
import pdfImage from "../../resources/pdf.png";

function Gallery(props) {
  const [items, setItems] = useState([]);
  const [firstItems, setFirstItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  const splitIndex = 4;

  useEffect(() => {
    // console.log("loading json...");
    var jsonArr = props.itemJson;
    var arrLength = jsonArr.length;
    // var tempFirstItems = jsonArr.splice(splitIndex);

    console.log("jsorArr", jsonArr);

    var dataArrChunks = [];

    for (var i = 0; i < arrLength; i++) {
      var obj = jsonArr[i];

      console.log(obj.id);
    }

    // for (var i in arrLength) {
    //   console.log(i);
    //   var chunkArr = jsonArr.slice(i, i + 2);
    //   console.log("chunkArr:", jsonArr.slice(i, i + 2));

    //   dataArrChunks.push(chunkArr);
    // }

    console.log("dataArrChunks:", dataArrChunks);
    // console.log("jsonArr:", jsonArr[0][0]);

    // setFirstItems(tempFirstItems);
    setItems(jsonArr);
  }, [props.itemJson]);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset;

      if (currentPosition > 500) {
        setShowScrollBtn(true);
      } else {
        setShowScrollBtn(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleImgClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handlePdfClick = (item) => {
    // setSelectedItem(item);
    // setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  let counter = 0;
  const handleImgLoad = () => {
    counter += 1;
    if (counter >= splitIndex) {
      setLoaded(true);
    }
  };

  const scrollToTop = () => {
    let scrollDuration = 500;
    var scrollStep = -window.scrollY / (scrollDuration / 15),
      scrollInterval = setInterval(function () {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else clearInterval(scrollInterval);
      }, 15);
  };

  return (
    <React.Fragment>
      {showModal && (
        <>
          <Modal
            handleModalClose={handleModalClose}
            item={selectedItem}
            additionalHtml={props.additionalModalHtml}
          />
        </>
      )}
      {!loaded && (
        <div style={{ textAlign: "center", marginBottom: "10vh" }}>
          <LottieAnimation src={loadingLottie} width={250} />
          <p>Loading {props.title}...</p>
        </div>
      )}

      <div className="row">
        <>
          {items.map((item) => (
            <div className="col-lg-4 portfolio-item" key={item.id}>
              <div className="card border-none h-100">
                <div className="vendor-card-img-container">
                  {item.img.endsWith("mov") || item.img.endsWith("mp4") ? (
                    <>
                      <video controls autoplay muted style={{ width: "100%" }}>
                        <source src={item.img} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </>
                  ) : item.img.endsWith("pdf") ? (
                    <a href={item.img} target="_blank">
                      <img
                        style={loaded ? {} : { display: "none" }}
                        className="dress-card-img store-img"
                        src={item.thumbnail ? item.thumbnail : pdfImage}
                        alt={item.id}
                        // onClick={() => handleImgClick(item)}
                        onLoad={() => handleImgLoad(item.id)}
                      />
                    </a>
                  ) : (
                    <img
                      style={loaded ? {} : { display: "none" }}
                      className="dress-card-img store-img"
                      src={item.img}
                      alt={item.id}
                      onClick={() => handleImgClick(item)}
                      onLoad={() => handleImgLoad(item.id)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      </div>

      {showScrollBtn && (
        <span onClick={() => scrollToTop()} className="scroll-top-btn">
          <i className="fa fa-arrow-up fab"></i>
        </span>
      )}
    </React.Fragment>
  );
}
export default Gallery;
