const itemJson = [
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-10.jpg",
    id: "sophia-engineering-10.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-11.jpg",
    id: "sophia-engineering-11.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-13.jpg",
    id: "sophia-engineering-13.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-12.jpg",
    id: "sophia-engineering-12.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-16.jpg",
    id: "sophia-engineering-16.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-17.jpg",
    id: "sophia-engineering-17.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-23.jpeg",
    id: "sophia-engineering-23.jpeg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-15.jpg",
    id: "sophia-engineering-15.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-14.jpg",
    id: "sophia-engineering-14.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-9.jpg",
    id: "sophia-engineering-9.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-8.jpg",
    id: "sophia-engineering-8.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-2.mp4",
    id: "sophia-engineering-2.mp4",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-1.jpg",
    id: "sophia-engineering-1.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-3.pdf",
    id: "sophia-engineering-3.pdf",
    thumbnail:
      "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/unlv-aiaa.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-5.jpg",
    id: "sophia-engineering-5.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-4.jpg",
    id: "sophia-engineering-4.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-6.jpg",
    id: "sophia-engineering-6.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-7.jpg",
    id: "sophia-engineering-7.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-19.jpg",
    id: "sophia-engineering-19.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-18.jpg",
    id: "sophia-engineering-18.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-22.jpg",
    id: "sophia-engineering-22.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-20.jpg",
    id: "sophia-engineering-20.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/engineering/sophia-engineering-21.jpg",
    id: "sophia-engineering-21.jpg",
  },
];

export default {
  itemJson,
};
