const itemJson = [
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-11.jpg",
    id: "sophia-art-11.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-10.jpg",
    id: "sophia-art-10.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-12.jpg",
    id: "sophia-art-12.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-13.jpg",
    id: "sophia-art-13.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-17.PNG",
    id: "sophia-art-17.PNG",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-16.PNG",
    id: "sophia-art-16.PNG",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-14.jpg",
    id: "sophia-art-14.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-9.jpg",
    id: "sophia-art-9.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-28.png",
    id: "sophia-art-28.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-29.png",
    id: "sophia-art-29.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-8.jpg",
    id: "sophia-art-8.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-15.jpg",
    id: "sophia-art-15.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-5.jpg",
    id: "sophia-art-5.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-30.png",
    id: "sophia-art-30.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-18.PNG",
    id: "sophia-art-18.PNG",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-19.PNG",
    id: "sophia-art-19.PNG",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-31.png",
    id: "sophia-art-31.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-4.jpg",
    id: "sophia-art-4.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-25.jpg",
    id: "sophia-art-25.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-6.jpg",
    id: "sophia-art-6.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-33.png",
    id: "sophia-art-33.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-27.png",
    id: "sophia-art-27.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-26.png",
    id: "sophia-art-26.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-32.png",
    id: "sophia-art-32.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-7.jpg",
    id: "sophia-art-7.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-22.jpg",
    id: "sophia-art-22.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-3.jpg",
    id: "sophia-art-3.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-2.jpg",
    id: "sophia-art-2.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-21.jpg",
    id: "sophia-art-21.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-35.png",
    id: "sophia-art-35.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-34.png",
    id: "sophia-art-34.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-1.png",
    id: "sophia-art-1.png",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-20.jpg",
    id: "sophia-art-20.jpg",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-24.mov",
    id: "sophia-art-24.mov",
  },
  {
    img: "https://media-hosting-bucket.s3.us-west-2.amazonaws.com/sophia-leon/art/sophia-art-23.mov",
    id: "sophia-art-23.mov",
  },
];

export default {
  itemJson,
};
