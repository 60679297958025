import React from "react";
// import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";

function Footer(props) {
  return (
    <React.Fragment>
      <div className="footer-container">
        <div className="footer-wrapper">
          <p>
            <span className="navbar-brand">Sophia Leon</span>
          </p>

          <div className="text-center fa-size">
            {/* the following {} should be kept due to achor tags requiring content */}
            {/* <a
              href="https://www.instagram.com/bowtiesbridal/"
              className="fa fa-instagram social"
            >
              {}
            </a>

            <a
              href="https://www.pinterest.com/bowtiesbridal/"
              className="fa fa-pinterest social"
            >
              
            </a> */}
          </div>
          <p className="m-0 text-center text-black">
            Copyright &copy; Sophia Leon 2021
          </p>

          <p className="powered-text">
            Powered by{" "}
            <a
              href="https://cloudsurfsoftware.com/"
              className="outerlink"
              target="_blank"
              rel="noopener noreferrer"
            >
              CloudSurf
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;
